const strategyMap = {
  wrap: null,
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  breakword: {
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  auto: {
    overflow: 'auto',
  },
} as const;

export type OverflowStrategyType = keyof typeof strategyMap;

export function useOverflowStrategyStyles(
  strategy: OverflowStrategyType,
  numberOfLines?: number
) {
  if (numberOfLines) {
    return {
      display: 'webkit-box',
      WebkitLineClamp: numberOfLines,
      WebktBoxOrient: 'vertical',
      overflow: 'hidden',
    } as const;
  }
  return strategyMap[strategy];
}
