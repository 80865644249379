import { Text as ChakraText, forwardRef } from '@chakra-ui/react';
import { useOverflowStrategyStyles } from './useOverflowStrategy';
import type { OverflowStrategyType } from './useOverflowStrategy';
import type { TextProps } from '@chakra-ui/react';
export const Text = forwardRef<
  TextProps & { overflow?: OverflowStrategyType },
  'div'
>((props, ref) => {
  const overflowStyles = useOverflowStrategyStyles(props.overflow || 'wrap');

  return (
    <ChakraText
      ref={ref}
      as='div'
      marginTop='0 !important'
      textStyle='default'
      css={overflowStyles}
      {...props}
    />
  );
});
